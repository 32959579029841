@import "./bootstrap-overrides";

$sm: map-get($container-max-widths, sm);

div.modal {
  div.modal-dialog {
    @media (max-width: $sm) {
      width: 100% !important;
      height: 100% !important;
      margin: 0px !important;
    }
    div.modal-content {
      @extend .bg-dark, .text-light, .border-dark, .w-100;
      @media (max-width: $sm) {
        height: 100% !important;
        border-radius: 0px;
      }
      div.modal-header,
      div.modal-body,
      div.modal-footer {
        @extend .bg-dark, .border-secondary;
        button.close {
          color: inherit;
        }
      }
      div.modal-body {
        overflow-y: auto;
      }
      img.image-preview {
        @extend .img-fluid, .rounded, .mw-100, .mh-100;
      }
    }
  }
}
