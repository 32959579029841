@import "./bootstrap-overrides";

div.rich-text-editor {
  @extend .mt-2, .mb-2, .w-100, .bg-light, .text-dark;
  border-radius: 0.25em;
  overflow: hidden;

  div.rich-text-editor__controls,
  div.rich-text-editor__editor-type {
    @extend .w-100, .bg-primary;
  }

  div.rich-text-editor__editor-type {
    border-radius: 0.25em 0.25em 0em 0em;
  }

  div.btn-group {
    button.btn {
      border-radius: 0em;
    }
  }

  .rich-text-editor__text-box {
    @extend .text-left, .p-2, .bg-light, .text-dark;
    height: 85vh !important;
    overflow-y: scroll;
    @at-root #{selector-unify(&, textarea)} {
      @extend .form-control-plaintext;
      resize: none;
    }
  }
}

@import "~draft-js/dist/Draft";
